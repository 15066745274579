
























































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  Configuration,
  RedCapital,
  SelectedCountry,
  TitleTemplatePublic
} from "@/configuration";
import API from "@/api";
import { EventBus } from "@/main";
import CurrencyInput from "@/components/Currency.vue";
import DniPerson from "@/components/DniField.vue";
import DniBusiness from "@/components/DniFieldBusiness.vue";
import { isRutValid } from "@/utils";
import { mapState } from "vuex";
import Axios from "axios";

@Component({

    data() {
      return {
        e1: 1,
        numeroactividad:0,
        siguiente:true,
        dialog:false,
        dialog2:false,
        dialog3:false,
        dialog4:false,
        imageName: '',
		imageUrl: '',
        imageFile: '',
        actividades:[21,22,23,24],
            respuesta:[],
            realizados1:[],
            r1:[21,22,23,24],
            result1:[],
        FormOne:{
            answers: [
                {
                    user_id:  this.$store.state.user.id,
                    cta_answer_id: null,
                    respuesta: null
                },
                {
                    user_id:  this.$store.state.user.id,
                    cta_answer_id: null,
                    respuesta: null
                },
                {
                    user_id:  this.$store.state.user.id,
                    cta_answer_id: null,
                    respuesta: null
                }
            ]
        },
        FormTwo:{
            user_id:  this.$store.state.user.id,
            cta_answer_id: "221",
            respuesta: null
        },
        FormThree:{
           answers: [
                {
                    user_id:  this.$store.state.user.id,
                    cta_answer_id: "231",
                    respuesta: null
                }
            ]
        },
        FormFour:{
            answers: [
                {
                    user_id:  this.$store.state.user.id,
                    cta_answer_id: "241",
                    respuesta: null
                }
            ]
        },
        rules: {
            notEmpty: [
                (v: any) => !!v || "Ingrese un valor",
                (v: any) => v.length > 3 || "Minimo 4 caracteres"
                ],
            phone: [
            (v: any) => !(/[^\d]/gim.test(v)) || 'Tiene que ser solo numeros',
            (v: any) => !!v || 'Teléfono es requerido',
            ],
            }
        }
    },methods:{
        gosabermas(){
            this.$router.push({
                path: "/actividad/sabermas"
            })
        },
         verbeneficios(numbeneficio){
            this.$data.dialog4 = true 
        },
        vermodalmembresias(numeromodalmembresia){
            this.$data.dialog3 = true
        },
        cerrarmodal(modal){
            if(modal == 1){
                this.$data.dialog1 = false
            }else if(modal == 2){
                this.$data.dialog2 = false
            }
            else if(modal == 3){
                this.$data.dialog3 = false
            }
            else if(modal == 4){
                this.$data.dialog4 = false
            }
        },
       proximostep(){
            //this.$data.e1 += step;
            const registrar = new Promise((resolve,reject)=>{
                setTimeout(()=>{
                    resolve('datos registrados')
                },1000)
            })
            registrar
            .then((mensaje:any)=> {
                console.log(mensaje)
                 this.$data.dialog = true
                 EventBus.$emit("snack-success", "Datos registrados correctamente");
                })
            .catch(err => console.warn(err))
           

        },
        comprension(){
            API.actividad.costos.comprension(this.$data.FormOne)
                .then((resolve)=>{
                    //@ts-ignore
                        this.obtenerdatos()
                        .then((res:any) =>{
                            this.$data.numeroactividad = 1;
                            EventBus.$emit("snack-success", "Datos registrados correctamente");
                            if(res.length == 4){
                                this.$data.dialog2 = true
                            }else{
                                    if(res.indexOf(22) > -1){
                                        this.$data.siguiente=false;
                                        this.$data.dialog = true
                                    }else{
                                        this.$data.siguiente=true;
                                        this.$data.dialog = true
                                    }
                            }
                        })
                })
                .catch((error) =>{
                    console.log(error)
                })
            
        },
        pickFile () {
            //@ts-ignore
            this.$refs.image.click ()
        },
		onFilePicked (e) {
			const files = e.target.files
			if(files[0] !== undefined) {
				this.$data.imageName = files[0].name
				if(this.$data.imageName.lastIndexOf('.') <= 0) {
					return
				}
				const fr = new FileReader ()
				fr.readAsDataURL(files[0])
				fr.addEventListener('load', () => {
					this.$data.imageUrl = fr.result
                    this.$data.imageFile = files[0] // this is an image file that can be sent to server...
         
				})
			} else {
				this.$data.imageName = ''
				this.$data.imageFile = ''
				this.$data.imageUrl = ''
            }
            this.$data.FormTwo.respuesta = files[0]
            //@ts-ignore
            this.subirimg();
		},
        subirimg(){
           
            const data = new FormData();
            data.append('user_id', this.$data.FormTwo.user_id);
            data.append('cta_answer_id', this.$data.FormTwo.cta_answer_id);

            data.append('respuesta', this.$data.FormTwo.respuesta);
            console.log(data)
             Axios.post(`${Configuration.api.base}/cta/cta22`, 
             
               data
             ,{
            headers: {
                'Content-type':'multiart/form-data',
                Authorization: `Bearer ${this.$store.state.token}`
            }
          }).then(res=>{
                //@ts-ignore
                        this.obtenerdatos()
                        .then((res:any) =>{
                            this.$data.numeroactividad = 2;
                            EventBus.$emit("snack-success", "Datos registrados correctamente");
                            if(res.length == 4){
                                this.$data.dialog2 = true
                            }else{
                                  if(res.indexOf(23) > -1) {
                                        this.$data.siguiente=false;
                                        this.$data.dialog = true
                                    }else{
                                        this.$data.siguiente=true;
                                        this.$data.dialog = true
                                    }
                                
                            }
                        })
          }).catch(error => console.log(error))
        },
        dejastedevender(){
            if(this.$data.FormThree.answers[0].respuesta.length >= 50){
                API.actividad.costos.dejastedevender(this.$data.FormThree)
                    .then((resolve)=>{
                        //@ts-ignore
                            this.obtenerdatos()
                            .then((res:any) =>{
                                this.$data.numeroactividad = 3;
                                EventBus.$emit("snack-success", "Datos registrados correctamente");
                                if(res.length == 4){
                                    this.$data.dialog2 = true
                                }else{

                                        if(res.indexOf(24) > -1){
                                            this.$data.siguiente=false;
                                            this.$data.dialog = true
                                        }else{
                                            this.$data.siguiente=true;
                                            this.$data.dialog = true
                                        }

                                }
                            })
                    })
                    .catch((error) =>{
                        console.log(error)
                    })
            }else {
                let total = 50 - this.$data.FormThree.answers[0].respuesta.length
                EventBus.$emit("snack-error", `Faltan ${total} caracteres`);
            }
            

        },
        prodestrella(){
            if(this.$data.FormFour.answers[0].respuesta.length >= 50){
                API.actividad.costos.prodestrella(this.$data.FormFour)
                    .then((resolve)=>{
                    //@ts-ignore
                            this.obtenerdatos()
                            .then((res:any) =>{
                                this.$data.numeroactividad = 4;
                                EventBus.$emit("snack-success", "Datos registrados correctamente");
                                if(res.length == 4){
                                    this.$data.dialog2 = true
                                }else{
                                    if(res.indexOf(24) > -1){
                                            this.$data.siguiente=false;
                                            this.$data.dialog = true
                                        }else{
                                            this.$data.siguiente=true;
                                            this.$data.dialog = true
                                        }
                                }
                            })
                    })
                    .catch((error) =>{
                        console.log(error)
                    })
            }else{
                 let total = 50 - this.$data.FormFour.answers[0].respuesta.length
                EventBus.$emit("snack-error", `Faltan ${total} caracteres`);
            }
             
        }   
        ,godashboard(){
            this.$router.push({
                path: "/actividad/administracion"
            })
        },
         obtenerdatos(){
           return new Promise((resol,reje)=>{
               API.actividad.infoactividades.actividadesrealizadas(this.$store.state.user.id)
                .then((resolve:any)=>{
                    for(let i=0; i < this.$data.actividades.length ;i++){
                        for(let j=0; j < resolve.data.data.length ;j++){
                            if(this.$data.actividades[i]===resolve.data.data[j].cta_list_id){
                                if((this.$data.actividades[i] < 25) && (this.$data.actividades[i] > 15)){
                                    this.$data.realizados1.push(this.$data.actividades[i])
                                }
                            }
                        }
                    }
                    resol(this.$data.realizados1 = Array.from(new Set(this.$data.realizados1)))      
                }).catch(error => console.log(error))
           })
        },
        next(){
            setTimeout(()=>{
                    this.$data.dialog = false
                    this.$data.e1 += 1;
                },1000)
            
        }
    },async beforeMount(){
        let etapa = this.$route.params.etapa;
        if (this.$route.params.etapa != null){
            this.$data.e1 = this.$route.params.etapa
        }
        API.actividad.infoactividades.actividadesrealizadas(this.$store.state.user.id)
                .then((resolve:any)=>{
                    for(let i=0; i < this.$data.actividades.length ;i++){
                        for(let j=0; j < resolve.data.data.length ;j++){
                            if(this.$data.actividades[i]===resolve.data.data[j].cta_list_id){
                                if((this.$data.actividades[i] < 25) && (this.$data.actividades[i] > 15)){
                                    this.$data.realizados1.push(this.$data.actividades[i])
                                }
                            }
                        }
                    }
                    console.log(this.$data.realizados1)
                    this.$data.realizados1 = Array.from(new Set(this.$data.realizados1))
                }).catch(error => console.log(error))
    }
  
})
export default class OrganizaTusFinanzas extends Vue {}
